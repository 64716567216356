<template>
    <div class="container-fluid">
        <template v-if="incidentId && isUserFound">
            <conversation :incidentId="incidentId"></conversation>
        </template>
        <div v-else>
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-5 col-8">
                    <div class="text-center">
                        <div>
                            <img src="@assets/images/not-found.png" alt class="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <h3 class="mt-3">Incident</h3>
                    <p class="text-muted mb-5">
                        Incident not found or incident has been closed.
                    </p>
                    <router-link to="#" class="btn btn-lg btn-primary mt-4">Return</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import conversation from "./respondConversation"
import {
    authenticateData
} from '@/src/state/api/authenticate';

export default {
    data() {
        return {
            currentDateTime: new Date(),
            isUserFound: false
        }
    },
    components: {
        conversation,
    },
    computed: {
        locationId() {
            return this.$route.params.locationId;
        },
        incidentId() {
            return this.$route.params.incidentId;
        },
        loginName() {
            return this.$route.query.loginName;
        },
        loginMobile() {
            return this.$route.query.loginMobile;
        },
        loginCompany() {
            return this.$route.query.loginCompany;
        },
    },
    async created() {
        this.isUserFound = false;
        await this.createUser();
        if (this.$store.getters.loggedInUser != null) {
            this.isUserFound = true;
        }
    },
    methods: {
        async createUser() {

            if (!this.loginMobile) {
                this.isUserFound = false;
                return;
            }

            var result = await authenticateData.createUser({
                mobileNumber: this.loginMobile,
                name: this.loginName,
                requestDateTime: new Date(),
                ownerCompanyId: this.loginCompany,
                locationId: this.locationId
            })
            if (result.succeeded) {
                this.$store.dispatch("setLoggedInUser", {
                    mobile: this.loginMobile,
                    name: this.loginName,
                    token: result.data.token
                });
            }
        }
    }
}
</script>

<style>
#chat-form {
    position: fixed;
    left: 0;
    bottom: 0px;
    width: 100%;
    background-color: #dddddd;
    color: white;
    text-align: center;
    padding: 10px;
}
</style>